import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { Carousel } from 'react-bootstrap';
import { auth, signInWithEmailAndPassword, getUserData } from "../../firebase";
import logo from '../../assets/tm-logo.png';
// import PaxibayGetFunction from "../../service/PaxibayGetFunction";
import './SignIn.css';
import {
    Button,
    Form,
    FormGroup,
    FloatingLabel,
    Col,
    Container
} from "react-bootstrap";

export default function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, error] = useAuthState(auth);
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        fetch("https://pixabay.com/api/?key=31306542-a2b0f9560f4c318d3e773e961&q=" + encodeURIComponent('forest') + "&image_type=photo&pretty=ture&page=1&per_page=3")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    // this.setState({
                    //     forestimage: result.hits.largeImageURL,
                    // });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            );


        setTimeout(() => setLoading(false), 1000)
        getUserData(user).then((user) => {
            console.log(user)
            if (user !== null) {
                user.forEach((ele) => {
                    var userData = ele.data();
                    setUserData(userData)
                    navigate("/dashboard");
                });
            }
        }).catch((err) => console.log(err));
    }, [user, error]);

    return (
        <div className='rowcontainlogin'>
            <div className="loginbody md-6">
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                <Container className='form-signin'>
                    <Form className='form-signin'>
                        <img className="mb-3" src={logo} alt="Thant Myanmar Logo" height="64"></img>
                        <h3 className="h3 mb-3 fw-normal">Log in</h3>
                        <h3 className="h6 mb-4 fw-normal">to Thant Myanmar Contant Management Portal</h3>

                        <FormGroup className='pt-2'>
                            <Col md={12} className='forminputemail'>
                                <FloatingLabel controlId="floatingInput" label="Email address" className="form-floating">
                                    <Form.Control type="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col md={12} className='forminputpassword'>
                                <FloatingLabel controlId="floatingPassword" label="Password" className='form-floating'>
                                    <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </FormGroup>
                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
                        <Button variant="subbtn btn-primary mt-2" onClick={() => signInWithEmailAndPassword(auth, email, password)}>
                            Login
                        </Button>
                        {/* <p className="mt-5 mb-3 text-muted text-center">Don’t have an account? <Link to="/signup">Sign up Here!</Link></p> */}
                        <p className="my-3 text-muted text-center">ThantMyanmar &copy; 2022–2023</p>
                    </Form>
                </Container>
            </div>

            <div className="loginbody md-6">
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                <Container className='wallp'>
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block imgsize"
                                // src={this.state.forestimage.map(0)}

                                src="https://images.pexels.com/photos/109391/pexels-photo-109391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                alt="First slide"
                            />
                            <Carousel.Caption className='customCaption'>
                                <h3>LOVE WHERE YOU LIVE</h3>
                                <p>Walk with us on the road to change, NO PLASTICS. NO LITTER. THANT MYANMAR.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block imgsize"
                                src="https://images.pexels.com/photos/109391/pexels-photo-109391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                alt="Second slide"
                            />

                            <Carousel.Caption className='customCaption'>
                                <h3>TAKE ACTION</h3>
                                <p>Against Single Use Plastics</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block imgsize"
                                src="https://images.pexels.com/photos/109391/pexels-photo-109391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                alt="Third slide"
                            />

                            <Carousel.Caption className='customCaption'>
                                <h3>Mission</h3>
                                <p>Our mission is to reduce waste and its impact on the environment through a community led movement.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Container>
            </div>

        </div>
    );

}

