import React, { useEffect, useState } from 'react';
import { getCoursesData, removeCourseData } from '../../firebase.js';
import { Navbar, Container, Row, Col, Table, Button, Form, Modal } from "react-bootstrap";
import "../Dashboard/Dashboard.css";
import { BiEdit , BiTrash, BiMinus} from "react-icons/bi";
import CourseModal from "../CourseModel/CourseModal";
import Loading from "../Loading/Loading"
import Sidebar from "../Sidebar/Sidebar";

function Courses() {
    const [courseLists, addData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState("create");
    const [courseData, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [showDelete, setShow] = useState(false);
    const [delcourseData, setDeleteData] = useState({});

    useEffect(() => {
        getCoursesData().then((lists) => {
            var arrActivity = [];
            lists.forEach((ele) => {
                arrActivity.push(ele.data());
            });
            addData(arrActivity);
            setLoading(false)
        }).catch((err) => console.log(err));
    }, []); 
    console.log(courseLists);
    function updateCourse(activity) {
        let getCourseInfo = activity.data;
        setStatus("edit")
        setData(courseInfo => ({ ...courseInfo, ...getCourseInfo }));
        setShowModal(true)
        
    }
    
    function deleteCourse() {
        let delData = delcourseData.data;
        console.log(delData) 
        setShow(false)
        setLoading(true);
        addData(courseLists.filter((item) => item.id !== delData.id));  
        removeCourseData(delData.id).then(() => {
            setTimeout(() => setLoading(false), 1000)
        });
    }

    const searchItems = (searchValue) => { 
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = courseLists.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }else{
            setFilteredResults(courseLists)
        }
    }

    return (
        <>
            {loading === false ? (
                <Container fluid>
                    <Row>
                        <Sidebar />
                        <Col lg={10} md={9} className="ms-sm-auto px-md-4">
                            <Navbar>
                                <h3 className='py-3'>All Courses</h3><h3 className='py-3 slagcolor'></h3> 
                                <Col md={8} className='py-3'>
                                    <Form className='p-1 col-md-8 mx-auto searchBox'>
                                        <Form.Control type="text" placeholder="Search by Course" className='text-center rounded-lg'  onChange={(e) => searchItems(e.target.value)}/>
                                    </Form>
                                </Col>
                                <Col md={2} className="d-flex align-items-center justify-content-end">
                                    <Button variant="primary"  onClick={() => {setShowModal(true); setStatus("create")}}>Create Course</Button>
                                    <CourseModal show={showModal} close={() => setShowModal(false)} status={status} course={courseData} />
                                </Col>
                            </Navbar>
                            <Container className='datacontainer p-0'>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Title</th>
                                            <th>Image</th>
                                            <th>Description</th>
                                            <th>Category</th>
                                            <th>Udemy</th>
                                            <th>Youtube</th>
                                            <th>Other</th>
                                            <th className='text-center'>Page</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tabledata'>
                                    {searchInput.length > 1 ? (
                                            <> 
                                                {filteredResults.map((data, index) => (
                                                    <tr key={index} className="py-4">
                                                        <td><label>{index + 1}.</label></td>
                                                        <td className='col-md-2'><label>{data.title}</label></td>
                                                        <td className='col-md-3'><img src={data.image} className="img-fluid" alt={data.image} /></td>
                                                        <td className='col-md-5'>
                                                            <p> {data.description.split("\n").map((para,index) => ( 
                                                            <label  className='mb-3' key={index}>{para}</label>
                                                            ))}</p>     
                                                        </td>
                                                        <td className='col-md-1 text-center'><label>{data.category}</label></td>
                                                        <td className='col-md-1 text-center'>
                                                            { data.udemylink !== null ? (
                                                                <a href={data.udemylink} target="_blank" className="pt-1"> Link </a>
                                                            ) : (
                                                                <label className="pt-1"> <BiMinus /> </label>
                                                            )}
                                                        </td>
                                                        <td className='col-md-1 text-center'>
                                                            { data.youtubelink !== null ? (
                                                                <a href={data.youtubelink} target="_blank" className="pt-1"> Link </a>
                                                            ) : (
                                                                <label className="pt-1"> <BiMinus /> </label>
                                                            )}
                                                        </td>
                                                        <td className='col-md-1 text-center'>
                                                            { data.otherlink !== undefined && data.otherlink !== null ? (
                                                                <a href={data.otherlink} target="_blank" className="pt-1"> Link </a>
                                                            ) : (
                                                                <label className="pt-1"> <BiMinus /> </label>
                                                            )}
                                                        </td>
                                                        <td className='col-md-1 text-center'><label>{data.page}</label></td>
                                                        <td className='editIcon'> <BiEdit onClick={() => updateCourse({ data })} /><BiTrash onClick={() => {setShow(true);setDeleteData({ data })}} /></td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <>  
                                                {courseLists.map((data, index) => (
                                                    <tr key={index} className="py-4">
                                                        <td><label>{index + 1}.</label></td>
                                                        <td className='col-md-2'><label>{data.title}</label></td>
                                                        <td className='col-md-3'><img src={data.image} className="img-fluid" alt={data.image} /></td>
                                                        <td className='col-md-5'>
                                                            <p> {data.description.split("\n").map((para,index) => ( 
                                                            <label  className='mb-3' key={index}>{para}</label>
                                                            ))}</p>     
                                                        </td>
                                                        <td className='col-md-1 text-center'><label>{data.category}</label></td>
                                                        <td className='col-md-1 text-center'>
                                                            { data.udemylink !== undefined && data.otherlink !== null ? (
                                                                <a href={data.udemylink} target="_blank" className="pt-1"> Link </a>
                                                            ) : (
                                                                <label className="pt-1"> <BiMinus /> </label>
                                                            )}
                                                        </td>
                                                        <td className='col-md-1 text-center'>
                                                            { data.youtubelink !== undefined  && data.youtubelink !== null ? (
                                                                <a href={data.youtubelink} target="_blank" className="pt-1"> Link </a>
                                                            ) : (
                                                                <label className="pt-1"> <BiMinus /> </label>
                                                            )}
                                                        </td>
                                                        <td className='col-md-1 text-center'>
                                                            { data.otherlink !== undefined && data.otherlink !== null ? (
                                                                <a href={data.otherlink} target="_blank" className="pt-1"> Link </a>
                                                            ) : (
                                                                <label className="pt-1"> <BiMinus /> </label>
                                                            )}
                                                        </td>
                                                        <td className='col-md-1 text-center'><label>{data.page}</label></td>
                                                        <td className='editIcon'> <BiEdit onClick={() => updateCourse({ data })} /><BiTrash onClick={() => {setShow(true);setDeleteData({ data })}} /></td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </tbody>
                                </Table>
                            </Container>
                        </Col>
                    </Row>
                    <Modal show={showDelete} onHide={() => setShow(false)} centered aria-labelledby="deleteModal">
                        <Modal.Header closeButton>
                        <Modal.Title id="deleteModal">
                            Are you sure to delete this course?
                        </Modal.Title>
                        </Modal.Header> 
                        <Modal.Footer>
                                <Button variant="danger"   onClick={() => setShow(false)}> No </Button>
                                <Button variant="success"  onClick={() => deleteCourse()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            ) : (
                <Loading />
            )}
        </>
    );
}
export default Courses;