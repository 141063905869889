import { Nav } from "react-bootstrap";
import React, { useState } from 'react';
import './Sidebar.css'
import { BiBook, BiBulb, BiLogOut } from "react-icons/bi";
import { SiCoursera } from "react-icons/si"
import { FaProjectDiagram } from "react-icons/fa"
import logo from '../../assets/tm-logo.png';
import mticn from '../../assets/thantmticn.svg';
import { useLocation } from "react-router-dom";
import { logout } from "../../firebase";

export default function Sidebar() {
    const location = useLocation() 
    return (
        <Nav className='col-md-3 col-lg-2 d-md-block bg-light sidebar collapse customnav' defaultActiveKey={location.pathname}>
            <Nav.Item>
                <div className="text-center mb-4">
                    <img alt={logo} src={logo} className="logo" />
                </div>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/dashboard"><BiBook />News and Update</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/resources"><BiBulb />Resources</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/courses"><SiCoursera />Courses</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/projects"><FaProjectDiagram />Projects</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/" onClick={logout}><BiLogOut />Sign Out</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <div className="text-center mticncontainer">
                    <img alt={mticn} src={mticn} className='mticn' />
                </div>
            </Nav.Item>
        </Nav>
    );
} 