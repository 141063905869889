import React, { useEffect, useState } from 'react';
import { getNewsData , removeNewsData} from '../../firebase.js';
import { Navbar, Container, Row, Col, Table, Button, Form , Modal} from "react-bootstrap";
import { BiEdit, BiTrash, BiMinus } from "react-icons/bi";
import NewsModal from "../NewsModal/NewsModal";
import Sidebar from "../Sidebar/Sidebar";
import Loading from "../Loading/Loading"
import "./Dashboard.css";

function Dashboard() { 
    const [newsdata, getData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState("create");
    const [newsInfo, setData] = useState({});
    const [loading, setLoading] = useState(true); 
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]); 
    const [showDelete, setShow] = useState(false);
    const [delnewsData, setDeleteData] = useState({});

    useEffect(() => {
        getNewsData().then((lists) => { 
            var arrBook = [];
            lists.forEach((ele) => {
                arrBook.push(ele.data());
            });
            getData(arrBook);
            setLoading(false)
        }).catch((err) => console.log(err));
    }, []);
    
    function editNewsInfo(news) {
        console.log(news)
        let getNewsInfo = news.data;
        setShowModal(true)
        setStatus("edit")
        setData(newsInfo => ({ ...newsInfo, ...getNewsInfo }));
    }

    function deleteNewsInfo() {
        let newsData = delnewsData.data;
        setLoading(true);
        setShow(false);
        getData(newsdata.filter((item) => item.nid !== newsData.nid));
        removeNewsData(newsData.nid).then(() => { 
            setTimeout(() => setLoading(false), 1000)
        });
    }

    const searchItems = (searchValue) => { 
        setSearchInput(searchValue)
        console.log(searchValue)
        if (searchInput !== '') {
            const filteredData = newsdata.filter((item) => {
                console.log(item)
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }else{
            setFilteredResults(newsdata)
        }
    }

    return (
        <>
        {loading === false ? (
            <Container fluid>
                <Row>
                    <Sidebar/>
                    <Col lg={10} md={9} className="ms-sm-auto px-md-4"> 
                        <Navbar>
                            <h3 className='py-3'>News and Update</h3><h3 className='py-3 slagcolor'></h3>
                            <Col md={7} className='py-3'>
                                    <Form className='p-1 col-md-8 mx-auto searchBox'>
                                        <Form.Control type="text" placeholder="Search by News and Update" className='text-center rounded-lg'  onChange={(e) => searchItems(e.target.value)}/>
                                    </Form>
                            </Col>
                            <Col md={2} className="d-flex align-items-center justify-content-end"> 
                                <Button variant="primary" onClick={() => {setShowModal(true); setStatus("create")}}>Create News</Button>
                                <NewsModal show={showModal} close={() => setShowModal(false)} status={status} data={newsInfo} />
                            </Col>
                        </Navbar>
                        <Container className='datacontainer p-0'>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Title</th>
                                        <th>New Photo</th>
                                        <th>Description</th>
                                        <th>Category</th>
                                        <th className='col-md-1 text-center'>Date</th>
                                        <th className='col-md-1 text-center'>Link</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className='tabledata'>
                                    {searchInput.length > 1 ? (
                                        <> 
                                            {filteredResults.map((data,index) => (
                                                <tr key={index}>
                                                    <td><label>{index+1}.</label></td>
                                                    <td className='col-md-2'><label>{data.ntitle}</label></td>
                                                    <td className='col-md-3'><img src={data.nimage} className="w-100" /></td>
                                                    <td className='col-md-5'>
                                                        <p>
                                                        {data.ndescription.split("\n").map((para,index) => ( 
                                                        <label  className='mb-3' key={index}>{para}</label>
                                                        ))}</p>  
                                                    </td>
                                                    <td className='col-md-1 text-center'><label>{data.ncategory}</label></td>
                                                    <td className='col-md-1 text-center'>
                                                        { data.nlink !== undefined && data.nlink !== null ? (
                                                            <a href={data.nlink} target="_blank" className="pt-1"> Link </a>
                                                        ) : (
                                                            <label className="pt-1"> <BiMinus /> </label>
                                                        )}
                                                    </td>
                                                    <td className='col-md-1'><label>{data.ndate}</label></td>
                                                    <td className='editIcon'> <BiEdit onClick={() => editNewsInfo({ data })} /> <BiTrash onClick={() => {setShow(true);setDeleteData({ data })}} /></td> 
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <>  
                                            {newsdata.map((data,index) => (
                                                <tr key={index}>
                                                    <td><label>{index+1}.</label></td>
                                                    <td className='col-md-2'><label>{data.ntitle}</label></td>
                                                    <td className='col-md-3'><img src={data.nimage} className="w-100" /></td>
                                                    <td className='col-md-5'>
                                                        <p>
                                                        {data.ndescription.split("\n").map((para,index) => ( 
                                                        <label  className='mb-3' key={index}>{para}</label>
                                                        ))}</p>  
                                                    </td>
                                                    <td className='col-md-1'><label>{data.ncategory}</label></td> 
                                                    <td className='col-md-1 text-center'>
                                                        { data.nlink !== undefined && data.nlink !== null ? (
                                                            <a href={data.nlink} target="_blank" className="pt-1"> Link </a>
                                                        ) : (
                                                            <label className="pt-1"> <BiMinus /> </label>
                                                        )}
                                                    </td>
                                                    <td className='col-md-1'><label>{data.ndate}</label></td>
                                                    <td className='editIcon'> <BiEdit onClick={() => editNewsInfo({ data })} /> <BiTrash onClick={() => {setShow(true);setDeleteData({ data })}} /></td> 
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Container>
                    </Col>
                </Row>
                <Modal show={showDelete} onHide={() => setShow(false)} centered aria-labelledby="deleteModal">
                    <Modal.Header closeButton>
                    <Modal.Title id="deleteModal">
                        Are you sure to delete this news?
                    </Modal.Title>
                    </Modal.Header> 
                    <Modal.Footer>
                            <Button variant="danger"   onClick={() => setShow(false)}> No </Button>
                            <Button variant="success"  onClick={() => deleteNewsInfo()}> Yes</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        ) : (
            <Loading />
        )}
        </>
    );
}
export default Dashboard;