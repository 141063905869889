import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, FormText } from "react-bootstrap";
import { storage, createProject, updateProjectData } from '../../firebase.js';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { BsPlusSquareDotted, BsLink45Deg ,BsPencilSquare} from "react-icons/bs";
import "../ResourceModal/ResourceModal.css";
import moment from 'moment'

const CreateActivity = (props) => {
    console.log("props>>>>",props)
    const projectinfo = props.project;
    const [projectTitle, setPtitle] = useState("");
    const [projectDes, setPdes] = useState("");
    const [projectcoverimgUrl, setNimage] = useState(null);
    const handleClose = props.close;
    // const [date, setDate] = useState(projectinfo.pdate ? projectinfo.pdate : new Date().toLocaleDateString("en-GB"));
    const [date, setDate] = useState("");
    const [projectcategory, setPCategory] = useState(""); 
    const [page, setPage] = useState("");

    const [orgLists, setOrgLists] = useState([]);
    const [funderLists, setFunderLists] = useState([]); 
    const [ssDurationStart, setSsDurationStart] = useState("");
    const [ssDurationEnd, setSsDurationEnd] = useState("");
    const [rsTitle, setRstitle] = useState(null);
    const [rscoverimgUrl, setRsimage] = useState(null);
    const [rsFiletype, setRsfiletype] = useState(null);
    const [rsDate, setRsdate] = useState(null);
    const [rsFilesize, setRsfilesize] = useState(null);
    const [rsFileurl, setRsfileurl] = useState(null);
    const [resourceArr, setResourceArr] = useState([]);
    const [resData, setResData] = useState({}); 
    const [resourceUpdateArr, setUpdateResourceArr] = useState([]); 
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleLinkClose = () => setShow(false);

    useEffect(() => {
        setOrgLists(props.status === "create" ? [] : projectinfo.ssorg);
        setFunderLists(props.status === "create" ? [] : projectinfo.ssfundorg);
    }, [projectinfo.ssorg],[projectinfo.ssfundorg])

    const setProjectCoverUpload = (imageUpload) => {
        console.log(imageUpload)
        if (imageUpload === null) return;
        const imageRef = ref(storage, `project/${imageUpload.name}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setNimage(url)
            });
        });
    }
   
    const setResourceCoverUpload = (imageUpload) => {
        console.log(imageUpload)
        if (imageUpload === null) return;
        const imageRef = ref(storage, `projectresource/${imageUpload.name}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setRsimage(url)
            });
        });
    }

    const handleOrgChange = (event) => { 
        const target = event.target
        const checked = target.checked
        const name = target.name
        const getOrgInfo = {name,checked};
        console.log(orgLists)
        if (orgLists.filter((item) => item.name === name) && checked === true) {
            setOrgLists(prevArray => [...prevArray, getOrgInfo]);
        }else if(orgLists.filter((item) => item.name === name) && checked === false){
            setOrgLists(orgLists.filter((item) => item.name !== name));
        }else{
            setOrgLists(prevArray => [...prevArray, getOrgInfo]);
        }
    }

    const handleFunderOrgChange = (event) => { 
        const target = event.target
        const checked = target.checked
        const name = target.name
        const getFundOrgInfo = {name,checked};
        console.log(getFundOrgInfo)
        if (funderLists.filter((item) => item.name === name) && checked === true) {
            setFunderLists(prevArray => [...prevArray, getFundOrgInfo]);
        }else if(funderLists.filter((item) => item.name === name) && checked === false){
            setFunderLists(funderLists.filter((item) => item.name !== name));
        }else{
            setFunderLists(prevArray => [...prevArray, getFundOrgInfo])
        }
        console.log(funderLists)
    }

    const createProjects = () => {
        if(projectcategory === ""){
            setPCategory("education");
        }
        if(page === ""){
            setPage("education");
        }
        createProject(projectTitle, projectDes, projectcoverimgUrl, date, page, projectcategory, orgLists, funderLists, ssDurationStart, ssDurationEnd, resourceArr).then((res) => {
            handleClose();
            window.location.reload();
        });
    }

    const updateProjectInfo = () => {
        console.log("projectcategory>>",projectcategory)
        let upTitle = projectTitle === "" ? projectinfo.ptitle : projectTitle;
        let upDes = projectDes === "" ? projectinfo.pdescription : projectDes; 
        let upDate = date === "" ? projectinfo.pdate : date; 
        // let upCategory = projectcategory === "" ? projectinfo.pcategory : projectcategory; 
        let upCategory = projectcategory === "" ? "education" : projectcategory;
        let upPage = page === "" ? projectinfo.page !== undefined ? projectinfo.page : "" : page;
        // let upOrgLists = orgLists.length === 0 ? projectinfo.ssorg : orgLists; 
        // let upFundOrgLists = funderLists.length === 0 ? projectinfo.ssfundorg : funderLists; 
        let upOrgLists = orgLists; 
        let upFundOrgLists = funderLists; 
        let sProjectSDate = ssDurationStart === "" ? projectinfo.spdate : ssDurationStart;
        let eProjectSDate = ssDurationEnd === "" ? projectinfo.epdate : ssDurationEnd;  
        let updDateRsData;
        if(props.status === "edit" && resourceArr.length !== 0){
            updDateRsData = resourceArr;
        }else{
            updDateRsData = resourceUpdateArr.length === 0 ? projectinfo.resources : resourceUpdateArr;
        }
        // let updDateRsData = resourceUpdateArr.length === 0 ? projectinfo.resources : resourceUpdateArr;
        updateProjectData(projectinfo.id, upTitle, upDes, upDate, upPage, upCategory,  upOrgLists, upFundOrgLists, sProjectSDate, eProjectSDate, updDateRsData);
        handleClose();
    }

    const addResource = () => {  
        setShow(false)
        setResourceArr(oldArray => [{ 
            title: rsTitle,
            image: rscoverimgUrl,
            fileType: rsFiletype,
            fileSize: rsFilesize,
            date: rsDate,
            fileUrl: rsFileurl 
        }, ...oldArray]);
    }

    const updateResource = (arrIndex) => { 
        projectinfo.resources[arrIndex] = { 
            title: rsTitle !== null ? rsTitle : resData.title, 
            image: rscoverimgUrl !== null ? rscoverimgUrl : resData.image,
            fileType: rsFiletype !== null ? rsFiletype : resData.fileType,
            fileSize: rsFilesize !== null ? rsFilesize : resData.fileSize,
            date: rsDate !== null ? rsDate : resData.date,
            fileUrl: rsFileurl !== null ? rsFileurl : resData.fileUrl,
        };
        setUpdateResourceArr(projectinfo.resources);
        setShow(false)
    }
    
    return (
        <>
            <Modal show={props.show} centered size="lg">
                <Modal.Header closeButton onClick={props.close}>
                    {props.status === 'edit'? (
                        <Modal.Title>Edit Project</Modal.Title>
                    ):(
                        <Modal.Title>Add New Project</Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* <FormText>Project</FormText> */}
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>Project Title: <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0">
                                {props.status === "edit" ? (
                                    <Form.Control type="text" placeholder="Enter Project Title" defaultValue={projectinfo.ptitle} onChange={e => setPtitle(e.target.value)} />
                                ) : (
                                    <Form.Control type="text" placeholder="Enter Project Title" onChange={e => setPtitle(e.target.value)} />
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>Project Description :  <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0">
                                {props.status === "edit" ? (
                                    <Form.Control as="textarea" rows={3} placeholder="Enter Project Description" defaultValue={projectinfo.pdescription} onChange={e => setPdes(e.target.value)} />
                                ) : (
                                    <Form.Control as="textarea" rows={3} placeholder="Enter Resource Description" onChange={e => setPdes(e.target.value)} />
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>Upload Photo:<strong>*</strong></Form.Label>
                            <Col md={7} className="p-0">
                                {props.status === "edit" ? ( 
                                        <img src={projectinfo.pimage} className="border w-75" alt={projectinfo.pimage} />
                                ) : (
                                    <Form.Group controlId="formFileSm" className="fileInput">
                                        <Form.Control type="file" onChange={(e) => { setProjectCoverUpload(e.target.files[0]); }} />
                                    </Form.Group>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Date :  <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0">
                                {props.status === "edit" ? (
                                    <Form.Control required defaultValue={moment(projectinfo.pdate, "DD-MM-YYYY").locale('en').format('YYYY-MM-DD')} type="date" onChange={e => setDate(new Date(e.target.value).toLocaleDateString("en-GB"))} /> 
                                ) : (
                                    <Form.Control required type="date" onChange={e => setDate(new Date(e.target.value).toLocaleDateString("en-GB"))} />
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>{props.status === "edit" ? "Update Your Page :" : "Add Your Page :"} <strong>*</strong></Form.Label>
                            <Col md={7} className='p-0'>
                                {props.status === "edit" ? ( 
                                    <Form.Select defaultValue={projectinfo.page !== undefined ? projectinfo.page : ""}  onChange={e => setPage(e.target.value)}>
                                        {/* <option>Select Your Page</option> */}
                                        <option value="education">Education</option>
                                        <option value="rural">Rural</option>
                                        <option value="urban">Urban</option>
                                        <option value="research">Research</option>
                                    </Form.Select>
                                ) : ( 
                                    <Form.Select aria-label="Default select example"  onChange={e => setPage(e.target.value)}>
                                        <option value="education">Education</option>
                                        <option value="rural">Rural</option>
                                        <option value="urban">Urban</option>
                                        <option value="research">Research</option>
                                    </Form.Select>
                                )}
                            </Col> 
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Add Category for your Resource :  <strong>*</strong></Form.Label>
                            {props.status === "edit" ? (
                                    <Col md={7} className="p-0">  
                                       <Form.Select defaultValue={projectinfo.pcategory} onChange={e => setPCategory(e.target.value)}>
                                            {/* <option>Add Category for your Resource</option> */}
                                            <option value="education">Education</option>
                                            <option value="activity">Waste Reduction Activity</option>
                                            <option value="community">Community Waste Management</option>
                                            <option value="research">Research</option>
                                            <option value="urban">Urban Solid Waste Management</option>

                                            <option value="behaviour">Behaviour change</option>
                                            <option value="erosion">Soil erosion</option>
                                            <option value="climate">Climate smart agriculture</option>
                                            <option value="upcycling">Upcycling and women empowerment</option>
                                            {/* <option value="other">Other</option> */}
                                        </Form.Select>
                                    </Col>
                                ) : (
                                    <Col md={7} className='p-0'>
                                        <Form.Select aria-label="Default select example" onChange={e => setPCategory(e.target.value)}>
                                            {/* <option>Add Category for your Resource</option> */}
                                            <option value="education">Education</option>
                                            <option value="activity">Waste Reduction Activity</option>
                                            <option value="community">Community Waste Management</option>
                                            <option value="research">Research</option>
                                            <option value="urban">Urban Solid Waste Management</option>
                                            <option value="behaviour">Behaviour change</option>
                                            <option value="erosion">Soil erosion</option>
                                            <option value="climate">Climate smart agriculture</option>
                                            <option value="upcycling">Upcycling and women empowerment</option>
                                            {/* <option value="other">Other</option> */}
                                        </Form.Select>
                                    </Col>
                                )} 
                        </Form.Group>
                        <hr />
                        <FormText>Add Partners and Funders</FormText>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Orgnization :  <strong>*</strong></Form.Label>
                            {props.status === "edit" ? (
                                <Col md={7} className='p-0 row'> 
                                {['YOMA Bank','Cordaid',"Oikos","FFI","Sequa, Stenum Asia","IGES","UN Habitat","Arche Nova","WIF", "Water Aid","Sittwe Green Network","Break free from Plastic","MCC","RCA", "DUHD","HBS","DPUIC","Union Aid","Chu Chu Recycle","WWF","Save the children"].map((orgname) => (
                                   <>   
                                    {Object.values(orgLists === undefined ? [] : orgLists).map((item) => item.name).includes(orgname) === true ? ( 
                                        <div key={'org_'+orgname} className="mb-3 col-md-4"> 
                                            <Form.Check
                                                type="checkbox"
                                                id={orgname}
                                                label={orgname}
                                                name={orgname}
                                                checked={true}
                                                onChange={e => handleOrgChange(e)}
                                            />
                                        </div> 
                                    ) : (
                                        <div key={'org_'+orgname} className="mb-3 col-md-4"> 
                                            <Form.Check
                                                type="checkbox"
                                                id={orgname}
                                                label={orgname}
                                                name={orgname} 
                                                onChange={e => handleOrgChange(e)}
                                            />
                                        </div> 
                                    )} 
                                    </>
                                ))}
                            </Col>
                            ) : (
                                <Col md={7} className='p-0 row'> 
                                    {['YOMA Bank','Cordaid',"Oikos","FFI","Sequa, Stenum Asia","IGES","UN Habitat","Arche Nova","WIF", "Water Aid","Sittwe Green Network","Break free from Plastic","MCC","RCA", "DUHD", "HBS","DPUIC","Union Aid","Chu Chu Recycle","WWF","Save the children"].map((orgname) => (
                                        <div key={'org_'+orgname} className="mb-3 col-md-4">
                                            <Form.Check
                                                type="checkbox"
                                                id={orgname}
                                                label={orgname}
                                                name={orgname}
                                                onChange={e => handleOrgChange(e)}
                                            />
                                        </div>
                                    ))}
                                </Col>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Funding Orgnization :  <strong>*</strong></Form.Label> 
                            {props.status === "edit" ? (
                                <Col md={7} className='p-0 row'> 
                                    {['German Embassy',"ADB","World Bank", "HBS", "Japan", "BMZ", "LIFT", "EU Switch Asia", "UNDP", "Asian Development Bank", "Sida", "UnionAid"].map((fundname) => ( 
                                        <>
                                            {Object.values(funderLists === undefined ? [] : funderLists).map((item) => item.name).includes(fundname) === true ? ( 
                                                <div key={'org_'+fundname} className="mb-3 col-md-4"> 
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={fundname}
                                                        label={fundname}
                                                        name={fundname}
                                                        checked={true}
                                                        onChange={e => handleFunderOrgChange(e)}
                                                    />
                                                </div> 
                                            ) : (
                                                <div key={'org_'+fundname} className="mb-3 col-md-4"> 
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={fundname}
                                                        label={fundname}
                                                        name={fundname} 
                                                        onChange={e => handleFunderOrgChange(e)}
                                                    />
                                                </div> 
                                            )} 
                                        </>
                                    ))}
                                </Col>
                            ) : (
                                <Col md={7} className='p-0 row'> 
                                    {['German Embassy',"ADB","World Bank", "HBS","Japan","BMZ","LIFT","EU Switch Asia","UNDP", "Asian Development Bank", "Sida", "UnionAid"].map((fundname) => (
                                        <div key={fundname} className="mb-3 col-md-4">
                                            <Form.Check
                                                type="checkbox"
                                                id={fundname}
                                                label={fundname}
                                                name={fundname}
                                                onChange={e => handleFunderOrgChange(e)}
                                            />
                                        </div>
                                    ))}
                                </Col>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Start Project Date :  </Form.Label>
                             <Col md={7} className='p-0'>
                                {props.status === "edit" ? ( 
                                    <Form.Control required defaultValue={moment(projectinfo.spdate, "DD-MM-YYYY").locale('en').format('YYYY-MM-DD')} type="date" onChange={e => {setSsDurationStart(new Date(e.target.value).toLocaleDateString("en-GB"));console.log("onchange....")}} />  
                                ) : (
                                    <Form.Control required type="date" onChange={e => {setSsDurationStart(new Date(e.target.value).toLocaleDateString("en-GB")); }} />
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> End Project Date :  </Form.Label>
                            <Col md={7} className='p-0'>
                                {props.status === "edit" ? (
                                    <Form.Control  defaultValue={moment(projectinfo.epdate, "DD-MM-YYYY").locale('en').format('YYYY-MM-DD')} type="date" onChange={e => setSsDurationEnd(new Date(e.target.value).toLocaleDateString("en-GB"))} />  
                                ) : (
                                    <Form.Control type="date" onChange={e => setSsDurationEnd(new Date(e.target.value).toLocaleDateString("en-GB"))} />
                                )}
                            </Col>
                        </Form.Group>
                        
                        <hr />
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>Resources :  </Form.Label>
                            <Col md={8} className='mt-0'>
                            {props.status === "edit" ? (
                                <>
                                    {projectinfo.resources.length > 0 ? (
                                        <>
                                            {projectinfo.resources.map((data, index) =>
                                                <Row key={index} className='p-0'>
                                                    <label><strong>Title : </strong>{data.title} </label>
                                                    <label><strong>Edit resource : </strong><BsPencilSquare className='ms-3 text-warning fs-5' onClick={() => {handleShow();setResData(data)}} /></label>
                                                    <Modal show={show} centered className='bg-dark' size="lg">
                                                    <Modal.Header>
                                                        <Modal.Title>Update Resources</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                    <Form.Group className="mb-4 row">
                                                        <Form.Label className='col-md-4'>Resource Title: </Form.Label>
                                                        <Col md={7} className='p-0'> 
                                                            <Form.Control type="text"  value={rsTitle !== null ? rsTitle : resData.title}  onChange={e => setRstitle(e.target.value)} />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-4 row">
                                                        <Form.Label className='col-md-4'>Upload Resource Photo:</Form.Label>
                                                        <Col md={7} className="p-0">
                                                            {resData.image !== null ? (
                                                                <img src={resData.image} className="border w-75" alt={resData.image} />
                                                            ) : (
                                                                <Form.Group controlId="formFileSm" className="fileInput">
                                                                    <Form.Control type="file" onChange={(e) => { setResourceCoverUpload(e.target.files[0]); }} />
                                                                </Form.Group>
                                                            )}
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-4 row">
                                                        <Form.Label className='col-md-4'> Resource Date :  </Form.Label>
                                                        <Col md={7} className='p-0'> 
                                                            <Form.Control  defaultValue={moment(resData.date, "DD-MM-YYYY").locale('en').format('YYYY-MM-DD')} type="date" onChange={e => setSsDurationStart(new Date(e.target.value).toLocaleDateString("en-GB"))} /> 
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-4 row">
                                                        <Form.Label className='col-md-4'> Type :  </Form.Label>
                                                        <Col md={7} className="p-0">
                                                            <Form.Select defaultValue={resData.fileType} onChange={e => setRsfiletype(e.target.value)}>
                                                                {/* <option>Add Filetype for your Project</option> */}
                                                                <option value="docx">Word File</option>
                                                                <option value="pptx">Powerpoint</option>
                                                                <option value="pdf">PDF</option>
                                                                <option value="image">Image</option>
                                                                <option value="url">Url</option> 
                                                            </Form.Select>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-4 row">
                                                        <Form.Label className='col-md-4'>File or Website Url: </Form.Label>
                                                        <Col md={7} className='p-0'>
                                                        <textarea className='form-control' type="textarea" placeholder="Enter Fileurl" defaultValue={resData.fileUrl} onChange={e => setRsfileurl(e.target.value)}></textarea>
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group className="mb-4 row">
                                                        <Form.Label className='col-md-4'>Filesize (Mb): </Form.Label>
                                                        <Col md={7} className='p-0'>
                                                            <Form.Control type="number" placeholder="Enter Filesize" defaultValue={resData.fileSize} onChange={e => setRsfilesize(e.target.value)} />
                                                        </Col>
                                                    </Form.Group>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleLinkClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="warning" onClick={() => updateResource(index)}>
                                                            Update
                                                            {/* already add res */}
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                </Row>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {resourceArr.length > 0 &&  (
                                                <>
                                                    {resourceArr.map((data, index) =>
                                                        <Row key={index} className='p-0'>
                                                            <strong> {data.title}</strong> 
                                                            <Col md={12} className='row'>
                                                                <BsLink45Deg className='col-md-1 link-icon text-success'/> <label  className='col-md-10'>{data.fileUrl}</label>
                                                            </Col> 
                                                        </Row>
                                                    )}
                                                </>
                                            )} 
                                            <Button variant="secondary" className='col-md-4' onClick={handleShow}><BsPlusSquareDotted className='me-2'/> Add Resource</Button> 
                                            <Modal show={show} centered className='bg-dark' size="lg">
                                                <Modal.Header>
                                                    <Modal.Title>Add Resources</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                <Form.Group className="mb-4 row">
                                                    <Form.Label className='col-md-4'>Resource Title: </Form.Label>
                                                    <Col md={7} className='p-0'> 
                                                        <Form.Control type="text" placeholder="Enter Resource Title" onChange={e => setRstitle(e.target.value)} />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="mb-4 row">
                                                    <Form.Label className='col-md-4'>Upload Resource Photo:</Form.Label>
                                                    <Col md={7} className="p-0">
                                                        <Form.Group controlId="formFileSm" className="fileInput">
                                                                <Form.Control type="file" onChange={(e) => { setResourceCoverUpload(e.target.files[0]); }} />
                                                            </Form.Group>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="mb-4 row">
                                                    <Form.Label className='col-md-4'> Resource Date :  </Form.Label>
                                                    <Col md={7} className='p-0'> 
                                                        <Form.Control type="date" onChange={e => setRsdate(new Date(e.target.value).toLocaleDateString("en-GB"))} />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="mb-4 row">
                                                    <Form.Label className='col-md-4'> Type :  </Form.Label>
                                                    <Col md={7} className='p-0'> 
                                                        <Form.Select aria-label="Default select example" onChange={e => setRsfiletype(e.target.value)}>
                                                                {/* <option>Add Filetype for your Project</option> */}
                                                                <option value="docx">Word File</option>
                                                                <option value="pptx">Powerpoint</option>
                                                                <option value="pdf">PDF</option>
                                                                <option value="image">Image</option>
                                                                <option value="url">Url</option>
                                                        </Form.Select>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="mb-4 row">
                                                    <Form.Label className='col-md-4'>File or Website Url: </Form.Label>
                                                    <Col md={7} className='p-0'>
                                                        <textarea className='form-control' type="textarea" placeholder="Enter Fileurl" onChange={e => setRsfileurl(e.target.value)}></textarea>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group className="mb-4 row">
                                                    <Form.Label className='col-md-4'>Filesize (Mb): </Form.Label>
                                                    <Col md={7} className='p-0'>
                                                        <Form.Control type="number" placeholder="Enter Filesize" onChange={e => setRsfilesize(e.target.value)} />
                                                    </Col>
                                                </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleLinkClose}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="warning" onClick={addResource}>
                                                        Add
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    )}  
                                </>
                            ):(
                                <>
                                {resourceArr.length > 0 && (
                                    <>
                                        {resourceArr.map((data, index) =>
                                            <Row key={index} className='p-0'>
                                                <strong> {data.title}</strong> 
                                                <Col md={12} className='row'>
                                                    <BsLink45Deg className='col-md-1 link-icon text-success'/> <label  className='col-md-10'>{data.fileUrl}</label>
                                                </Col> 
                                            </Row>
                                        )}
                                    </>
                                )} 
                                <Button variant="secondary" className='col-md-4' onClick={handleShow}><BsPlusSquareDotted className='me-2'/> Add Resource</Button> 
                                <Modal show={show} centered className='bg-dark' size="lg">
                                    <Modal.Header>
                                        <Modal.Title>Add Resources</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <Form.Group className="mb-4 row">
                                        <Form.Label className='col-md-4'>Resource Title: </Form.Label>
                                        <Col md={7} className='p-0'> 
                                            <Form.Control type="text" placeholder="Enter Resource Title" onChange={e => setRstitle(e.target.value)} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-4 row">
                                        <Form.Label className='col-md-4'>Upload Resource Photo:</Form.Label>
                                        <Col md={7} className="p-0">
                                            <Form.Group controlId="formFileSm" className="fileInput">
                                                    <Form.Control type="file" onChange={(e) => { setResourceCoverUpload(e.target.files[0]); }} />
                                                </Form.Group>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-4 row">
                                        <Form.Label className='col-md-4'> Resource Date :  </Form.Label>
                                        <Col md={7} className='p-0'> 
                                            <Form.Control type="date" onChange={e => setRsdate(new Date(e.target.value).toLocaleDateString("en-GB"))} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-4 row">
                                        <Form.Label className='col-md-4'> Type :  </Form.Label>
                                        <Col md={7} className='p-0'>
                                            <Form.Select aria-label="Default select example" onChange={e => setRsfiletype(e.target.value)}>
                                                    <option>Add Filetype for your Project</option>
                                                    <option value="docx">Word File</option>
                                                    <option value="pptx">Powerpoint</option>
                                                    <option value="pdf">PDF</option>
                                                    <option value="image">Image</option>
                                                    <option value="url">Url</option>
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-4 row">
                                        <Form.Label className='col-md-4'>File or Website Url: </Form.Label>
                                        <Col md={7} className='p-0'>
                                            <textarea className='form-control' type="textarea" placeholder="Enter Fileurl" onChange={e => setRsfileurl(e.target.value)}></textarea>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-4 row">
                                        <Form.Label className='col-md-4'>Filesize (Mb): </Form.Label>
                                        <Col md={7} className='p-0'>
                                            <Form.Control type="number" placeholder="Enter Filesize" onChange={e => setRsfilesize(e.target.value)} />
                                        </Col>
                                    </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleLinkClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="warning" onClick={addResource}>
                                            Add
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                </>
                            )} 
                            </Col>
                        </Form.Group> 
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {props.status === "edit" ? (
                        <Button variant="primary" onClick={updateProjectInfo}> Update Project </Button>
                    ) : (
                        <Button variant="primary" onClick={createProjects}> Create Project </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateActivity;