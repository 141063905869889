import React, { useEffect, useState } from 'react';
import { getProjectData , removeProjectData} from '../../firebase.js';
import { Navbar, Container, Row, Col, Table, Button, Form , Modal} from "react-bootstrap";
import "../Dashboard/Dashboard.css";
import { BiEdit , BiTrash} from "react-icons/bi";
import ProjectModal from "../ProjectModal/ProjectModal";
import Loading from "../Loading/Loading"
import Sidebar from "../Sidebar/Sidebar";

function Project() {
    const [projectLists, addData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState("create");
    const [projectInfo, setData] = useState({});
    const [loading, setLoading] = useState(true); 
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [showDelete, setShow] = useState(false);
    const [delprojectData, setDeleteData] = useState({});

    useEffect(() => {
        getProjectData().then((lists) => {
            var arrActivity = [];
            lists.forEach((ele) => {
                arrActivity.push(ele.data());
            });
            addData(arrActivity);
            setLoading(false)
        }).catch((err) => console.log(err));
    }, []);
    console.log(projectLists)

    function editActivityInfo(activity) {
        let getActInfo = activity.data;
        setShowModal(true)
        setStatus("edit")
        setData(actInfo => ({ ...actInfo, ...getActInfo }));
    }

    function deleteProject() {
        let projectData = delprojectData.data;
        setShow(false);
        addData(projectLists.filter((item) => item.id !== projectData.id));
        setLoading(true);
        console.log("projectData.id>>",projectData.id)
        removeProjectData(projectData.id).then(() => {
            setTimeout(() => setLoading(false), 1000)
        });
    }

    const searchItems = (searchValue) => { 
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = projectLists.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }else{
            setFilteredResults(projectLists)
        }
    }

    return (
        <>
            {loading === false ? (
                <Container fluid>
                    <Row>
                        <Sidebar />
                        <Col lg={10} md={9} className="ms-sm-auto px-md-4">
                            <Navbar>
                                <h3 className='py-3'>All Projects</h3>
                                <Col md={7} className='py-3'>
                                    <Form className='p-1 col-md-8 mx-auto searchBox'>
                                        <Form.Control type="text" placeholder="Search by Project" className='text-center rounded-lg'  onChange={(e) => searchItems(e.target.value)}/>
                                    </Form>
                                </Col>
                                <Col md={3} className="d-flex align-items-center justify-content-end">
                                    <Button variant="primary" onClick={() => {setShowModal(true); setStatus("create")}}>Create Project</Button>
                                    <ProjectModal show={showModal} close={() => setShowModal(false)} status={status} project={projectInfo} />
                                </Col>
                            </Navbar>
                            <Container className='datacontainer p-0'>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Title</th>
                                            <th>Image</th>
                                            <th>Description</th>
                                            <th>Organization</th>
                                            <th>Funder</th>
                                            <th>Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tabledata'>
                                        {searchInput.length > 1 ? (
                                            <> 
                                                {filteredResults.map((data, index) => (
                                                    <tr key={index} className="py-4">
                                                        <td><label>{index + 1}.</label></td>
                                                        <td className='col-md-2'><label>{data.ptitle}</label></td>
                                                        <td className='col-md-3'><img src={data.pimage} className="img-fluid" alt={data.pimage} /></td>
                                                        <td className='col-md-5'>
                                                            <p>{data.pdescription.split("\n").map((para,index) => ( 
                                                            <label  className='mb-3' key={index}>{para}</label>
                                                            ))}</p>      
                                                        </td>
                                                        <td className='col-md-1'>
                                                            {data.ssorg.map((org,index) => ( 
                                                                <label  className='mb-3' key={index}>{org.name}</label>
                                                            ))}
                                                        </td>
                                                        <td className='col-md-1'>
                                                        {data.ssfundorg.map((funder,index) => ( 
                                                                <label  className='mb-3' key={index}>{funder.name}</label>
                                                            ))}
                                                        </td>
                                                        <td className='editIcon'><BiEdit onClick={() => editActivityInfo({ data })} /><BiTrash onClick={() => {setShow(true); setDeleteData({ data })}} /></td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <> 
                                            {projectLists.map((data, index) => (
                                                    <tr key={index} className="py-4">
                                                        <td><label>{index + 1}.</label></td>
                                                        <td className='col-md-2'><label>{data.ptitle}</label></td>
                                                        <td className='col-md-3'><img src={data.pimage} className="img-fluid" alt={data.pimage} /></td>
                                                        <td className='col-md-5'>
                                                            <p>{data.pdescription.split("\n").map((para,index) => ( 
                                                            <label  className='mb-3' key={index}>{para}</label>
                                                            ))}</p>      
                                                        </td>
                                                        <td className='col-md-1'>
                                                            {data.ssorg.map((org,index) => ( 
                                                                <span className='mb-3 row' key={index}>{org.name}</span>
                                                            ))}
                                                        </td>
                                                        <td className='col-md-1'>
                                                            {data.ssfundorg.map((funder,index) => ( 
                                                                <span className='mb-3 row' key={index}>{funder.name}</span>
                                                            ))}
                                                        </td>
                                                        <td className='editIcon'><BiEdit onClick={() => editActivityInfo({ data })} /><BiTrash onClick={() => {setShow(true); setDeleteData({ data })}} /></td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </tbody>
                                </Table>
                            </Container>
                        </Col>
                    </Row>
                    <Modal show={showDelete} onHide={() => setShow(false)} centered aria-labelledby="deleteProjectModal">
                        <Modal.Header closeButton>
                        <Modal.Title id="deleteProjectModal">
                            Are you sure to delete this project?
                        </Modal.Title>
                        </Modal.Header> 
                        <Modal.Footer>
                                <Button variant="danger"   onClick={() => setShow(false)}> No </Button>
                                <Button variant="success"  onClick={() => deleteProject()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            ) : (
                <Loading />
            )}
        </>
    );
}
export default Project;