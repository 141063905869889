import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import logo from '../../assets/tm-logo.png';
import { Carousel } from 'react-bootstrap';
import { auth, registerWithEmailAndPassword } from "../../firebase";
import './SignUp.css';
import { Link, useNavigate } from "react-router-dom";
import {
    Button,
    Form,
    FormGroup,
    FloatingLabel,
    Col,
    Container
} from "react-bootstrap";
import Loading from "../Loading/Loading"

export default function SignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(true)
    const [user, error] = useAuthState(auth);
    const navigate = useNavigate();

    const Register = () => {
        if (!name) alert("Please enter name");
        registerWithEmailAndPassword(name, email, password);
    };
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
        if (user) {
            navigate("/dashboard")
        };
    }, [user, loading]);

    return (
        <>
            {loading === false ? (
                <div className='rowcontainlogin'>
                    <div className="loginbody md-6">
                        {/* <img src={logo} className="App-logo" alt="logo" /> */}
                        <Container className='form-signin'>
                            <Form className='form-signup'>
                                <img className="mb-3" src={logo} alt="Thant Myanmar Logo" height="64"></img>
                                <h3 className="h3 mb-3 fw-normal">Create Your Admin Account</h3>
                                <h3 className="h6 mb-4 fw-normal">to Thant Myanmar Contant Management Portal</h3>
                                <FormGroup className='pt-2'>
                                    <Col md={12} className='forminputemail'>
                                        <FloatingLabel controlId="floatingInput" label="Your Name" className="form-floating">
                                            <Form.Control type="text" placeholder="Please Enter Your Name" onChange={e => setName(e.target.value)} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel controlId="floatingInput" label="Email address" className="form-floating">
                                            <Form.Control type="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12} className='forminputpassword'>
                                        <FloatingLabel controlId="floatingPassword" label="Password" className='form-floating'>
                                            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                        </FloatingLabel>
                                    </Col>
                                </FormGroup>

                                <Button variant="subbtn btn-primary mt-2" onClick={Register}>
                                    Sign Up
                                </Button>
                                <p className="mt-5 mb-3 text-muted text-center">Already have an account? <Link to="/"> Login </Link> now.</p>
                                <p className="mb-3 text-muted text-center">ThantMyanmar &copy; 2022–2023</p>
                            </Form>
                        </Container>
                    </div>

                    <div className="loginbody md-6">
                        {/* <img src={logo} className="App-logo" alt="logo" /> */}
                        <Container className='wallp'>
                            <Carousel>
                                <Carousel.Item>
                                    <img
                                        className="d-block imgsize"
                                        // src={this.state.forestimage.map(0)}

                                        src="https://images.pexels.com/photos/109391/pexels-photo-109391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        alt="First slide"
                                    />
                                    <Carousel.Caption className='customCaption'>
                                        <h3>LOVE WHERE YOU LIVE</h3>
                                        <p>Walk with us on the road to change, NO PLASTICS. NO LITTER. THANT MYANMAR.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block imgsize"
                                        src="https://images.pexels.com/photos/109391/pexels-photo-109391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        alt="Second slide"
                                    />

                                    <Carousel.Caption className='customCaption'>
                                        <h3>TAKE ACTION</h3>
                                        <p>Against Single Use Plastics</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block imgsize"
                                        src="https://images.pexels.com/photos/109391/pexels-photo-109391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        alt="Third slide"
                                    />

                                    <Carousel.Caption className='customCaption'>
                                        <h3>Mission</h3>
                                        <p>Our mission is to reduce waste and its impact on the environment through a community led movement.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </Container>
                    </div>

                </div>
            ) : (
                <Loading />
            )}
        </>
    );

}

