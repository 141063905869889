import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./components/SignIn/SignIn";
import Dashboard from "./components/Dashboard/Dashboard";
import Resources from "./components/Resources/Resources";
import SignUp from "./components/SignUp/SignUp";
import Course from "./components/Courses/Courses";
import Project from "./components/Project/Project";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<SignIn />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/resources" element={<Resources />} />
          <Route exact path="/courses" element={<Course />} />
          <Route exact path="/projects" element={<Project />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;

