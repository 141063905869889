import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { doc, getFirestore, query, getDocs, collection, where, deleteDoc, setDoc, updateDoc, orderBy } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { encode } from 'base-64';

const firebaseConfig = {
  apiKey: "AIzaSyDJlb5U3V5tFHM67sx6zr9pWHA93dNZKew",
  authDomain: "thantmyanmar-1fcd5.firebaseapp.com",
  projectId: "thantmyanmar-1fcd5",
  storageBucket: "thantmyanmar-1fcd5.appspot.com",
  messagingSenderId: "98518273379",
  appId: "1:98518273379:web:7793f1fc88b344a368d756",
  measurementId: "G-YDVJSEDKHM"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const utf8 = require('utf8');

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const getUserData = async (user) => {
  if (user !== null) {
    try {
      const userdb = query(collection(db, "users"), where("uid", "==", user.uid));
      const getData = await getDocs(userdb);
      return getData;
    } catch (err) {
      console.error(err.message)
      return err;
    }
  } else {
    return null;
  }
}

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const encodedPwd = encode(utf8.encode(password));
    const user = res.user;
    const data = {
      uid: user.uid,
      name: name,
      email: email,
      password: encodedPwd,
      type: "admin"
    }
    await setDoc(doc(db, "users", user.uid), data);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
  localStorage.removeItem("user");
  setTimeout(() => window.location.reload("/"), 2000);
};


const getActivitiesData = async () => {
  try {
    const activityDb =  query(collection(db, "resources"), orderBy("title", "asc"));
    const getData = await getDocs(activityDb);
    return getData;
  } catch (err) {
    console.error(err.message)
  }
}

const getCoursesData = async () => {
  try {
    const courseDb =  query(collection(db, "courses"), orderBy("title", "asc"));
    const getData = await getDocs(courseDb);
    return getData;
  } catch (err) {
    console.error(err.message)
  }
}

const createCourse = async (ctitle, cdec, cimgUrl, cdate, ccategory,clabes, cpage, udemylink, youtubelink, otherlink) => {
  const generateUuid = uuidv4();
  const getUuid = generateUuid.replaceAll('-', '');
  console.log("getUuid " + getUuid)
  try {
    const coursesData = {
      id: getUuid,
      title: ctitle,
      description: cdec,
      image: cimgUrl,
      date: cdate,
      category: ccategory,
      labels: clabes,
      page: cpage,
      udemylink: udemylink,
      youtubelink: youtubelink,
      otherlink: otherlink
    }
    await setDoc(doc(db, "courses", getUuid), coursesData).then(() => {
      return "Successfull created activitiy.";
    }).catch((error) => {
      console.log(error)
      return error;
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const updateCourse = async (actId, ctitle, cdec, cdate, ccategory, clabes, cpage, udemylink, youtubelink, otherlink) => {
  console.log(actId, ctitle, cdec, cdate, ccategory, cpage, udemylink, youtubelink, otherlink)
  try {
    const activitydb = query(collection(db, "courses"), where("id", "==", actId));
    const getData = await getDocs(activitydb);
    getData.forEach((ele) => {
      const actDoc = doc(db, "courses", ele.id);
      updateDoc(actDoc, {
        title: ctitle,
        description: cdec,
        date: cdate,
        category: ccategory,
        labels: clabes,
        page: cpage,
        udemylink: udemylink,
        youtubelink: youtubelink,
        otherlink: otherlink
      }).then(() => { 
        console.log("updated news Info");
        window.location.reload();
      }).catch((error) => {
        console.log(error)
        return error;
      });
    });
  } catch (err) {
    console.error(err.message)
  }
}

const removeCourseData = async (courseId) => {
  console.log(courseId)
  await deleteDoc(doc(db, "courses", courseId)).then(() => {
    return "Course successfully deleted!";
  }).catch((error) => {
    console.error("Error removing document: ", error);
  });
}

const createNews = async (ntitle, ndesc, nimgUrl, ndate, ncategory, nlink) => {
  const rawnewsID = uuidv4();
  const newsID = rawnewsID.replaceAll('-', '');
  console.log('newsID' + newsID);
  try {
    const newsData = {
      nid: newsID,
      ntitle: ntitle,
      ndescription: ndesc,
      nimage: nimgUrl,
      ndate: ndate,
      ncategory: ncategory,
      nlink: nlink
    }
    await setDoc(doc(db, "news", newsID), newsData).then(() => {
      return "Successfull created activitiy.";
    }).catch((error) => {
      console.log(error)
      return error;
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

const updateNews = async (id, title, desc, date, category, link) => { 
  console.log(category)
  try {
    const newsCollection = query(collection(db, "news"), where("nid", "==", id));
    const getData = await getDocs(newsCollection);
    getData.forEach((ele) => { 
      const actDoc = doc(db, "news", ele.id);
      updateDoc(actDoc, {
        ntitle: title,
        ndescription: desc,
        ndate: date,
        ncategory: category,
        nlink: link
      }).then(() => {
        console.log("updated news Info");
        window.location.reload();
      }).catch((error) => {
        console.log(error)
        return error;
      });
    });
  } catch (err) {
    console.error(err.message)
  }
}

const removeNewsData = async (newsId) => {
  await deleteDoc(doc(db, "news", newsId)).then(() => {
    return "New successfully deleted!";
  }).catch((error) => {
    console.error("Error removing document: ", error);
  });
}

const getNewsData = async () => {
  try {  
    const newsDb =  query(collection(db, "news"), orderBy("ntitle", "asc"));
    const getData = await getDocs(newsDb);
    return getData;
  } catch (err) {
    console.error(err.message)
  }
}

const createResource = async (title, dec, imgUrl, date, newpage, category, linkArr) => {
  const generateUuid = uuidv4();
  const getUuid = generateUuid.replaceAll('-', '');
  try {
    const resourceData = {
      id: getUuid,
      title: title,
      description: dec,
      image: imgUrl,
      date: date,
      category: category,
      page: newpage,
      links: linkArr
    }
    await setDoc(doc(db, "resources", getUuid), resourceData).then(() => {
      return "Successfull created activitiy.";
    }).catch((error) => {
      console.log(error)
      return error;
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const updateResourcesData = async (actId, title, dec, category ,uppage, date, linkArr) => {
  try {
    const activitydb = query(collection(db, "resources"), where("id", "==", actId));
    const getData = await getDocs(activitydb);
    getData.forEach((ele) => {
      const actDoc = doc(db, "resources", ele.id);
      updateDoc(actDoc, {
        title: title,
        description: dec,
        date: date,
        category: category,
        page: uppage,
        links: linkArr
      }).then(() => {
        console.log("updated activity Info");
        window.location.reload();
      }).catch((error) => {
        console.log(error)
        return error;
      });
    });
  } catch (err) {
    console.error(err.message)
  }
}

const removeResourceData = async (projectId) => {
  await deleteDoc(doc(db, "resources", projectId)).then(() => {
    return "Resources successfully deleted!";
  }).catch((error) => {
    console.error("Error removing document: ", error);
  });
}

const getProjectData = async () => {
  try {
    const projectDb =  query(collection(db, "projects"), orderBy("ptitle", "asc"));
    const getData = await getDocs(projectDb);
    return getData;
  } catch (err) {
    console.error(err.message)
  }
}

const createProject = async (ptitle, pdec, pimgUrl, pdate, newpage, pcategory, ssorgArr, ssfundingorgArr, spdate, epdate, resourceArr) => {
  const generateUuid = uuidv4();
  const getUuid = generateUuid.replaceAll('-', '');
  console.log("getUuid " + getUuid)
  try {
    const projectData = {
      id: getUuid,
      ptitle: ptitle,
      pdescription: pdec,
      pimage: pimgUrl,
      pdate: pdate,
      page: newpage,
      pcategory: pcategory,
      ssorg: ssorgArr,
      ssfundorg: ssfundingorgArr,
      spdate: spdate,
      epdate: epdate,
      resources : resourceArr
    }
    await setDoc(doc(db, "projects", getUuid), projectData).then(() => {
      return "Successfull created activitiy.";
    }).catch((error) => {
      console.log(error)
      return error;
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const updateProjectData = async (projectId, ptitle, pdec, pdate, uppage, pcategory, ssorgArr, ssfundingorgArr, spdate, epdate, resourceArr) => {
  try {
    const projectdb = query(collection(db, "projects"), where("id", "==", projectId));
    const getProjData = await getDocs(projectdb);
    getProjData.forEach((ele) => {
      const projDoc = doc(db, "projects", ele.id);
      updateDoc(projDoc, {
        ptitle: ptitle,
        pdescription: pdec,
        pdate: pdate,
        page: uppage,
        pcategory: pcategory,
        ssorg: ssorgArr,
        ssfundorg: ssfundingorgArr,
        spdate: spdate,
        epdate: epdate,
        resources : resourceArr
      }).then(() => {
        console.log("updated project Info successfully");
        window.location.reload();
      }).catch((error) => {
        console.log(error)
        return error;
      });
    });
  } catch (err) {
    console.error(err.message)
  }
}


const removeProjectData = async (projectId) => {
  await deleteDoc(doc(db, "projects", projectId)).then(() => {
    console.log("delete db",db)
    return "remove Project successfully deleted!";
  }).catch((error) => {
    console.error("Error removing document: ", error);
  });
}


export {
  auth,
  db,
  storage,
  logInWithEmailAndPassword,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getUserData,
  createResource,
  removeResourceData,
  getActivitiesData,
  updateResourcesData,
  createNews,
  updateNews,
  removeNewsData,
  getNewsData,
  getCoursesData,
  createCourse,
  updateCourse,
  removeCourseData,
  getProjectData,
  createProject,
  updateProjectData,
  removeProjectData
};