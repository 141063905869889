import React, { useState } from 'react';
import moment from 'moment'
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { BsPlusSquareDotted, BsLink45Deg ,BsPencilSquare} from "react-icons/bs";
import { storage, createResource, updateResourcesData } from '../../firebase.js';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./ResourceModal.css"

const CreateActivity = (props) => { 
    const resource = props.resource;
    const [actTitle, setTitle] = useState("");
    const [actDes, setDes] = useState("");
    const [actUrl, setImageUrl] = useState(null);
    const handleClose = props.close;
    // const [date, setDate] = useState(new Date().toLocaleDateString("en-GB"));
    const [date, setDate] = useState("");
    const [category, setCategory] = useState("");
    const [linkData, setLinkData] = useState({});
    const [resTitle, setresTitle] = useState(null);
    const [reslink, setLink] = useState(null);
    const [linkArr, setLinksArr] = useState([]); 
    const [show, setShow] = useState(false);
    const [updateLinkArr, setUpdateLinksArr] = useState(resource.links !== undefined ? resource.links : []); 
    const handleShow = () => setShow(true);
    const handleLinkClose = () => setShow(false);
    const [page, setPage] = useState("");

    const addLinks = () => {  
        setShow(false)
        setLinksArr(oldArray => [{ title: resTitle, link: reslink }, ...oldArray]);
    }

    const updateLinks = (arrIndex) => { 
        resource.links[arrIndex] = { 
            title: resTitle !== null ? resTitle : linkData.title, 
            link: reslink !== null ? reslink : linkData.link 
        };
        console.log(resource.links)
        setUpdateLinksArr(resource.links);
        setShow(false)
    }

    const setCoverUpload = (imageUpload) => {
        console.log(imageUpload)
        if (imageUpload === null) return;
        const imageRef = ref(storage, `resources/${imageUpload.name}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setImageUrl(url)
            });
        });
    }

    const createResources = () => {
        if(category === ""){
            setCategory("education")
        }
        if(page === ""){
            setPage("university")
        }
        createResource(actTitle, actDes, actUrl, date, page, category, linkArr).then((res) => {
            console.log(res)
            handleClose();
            window.location.reload();
        });
    }

    const updateResources = () => { 
        let upRitle = actTitle === "" ? resource.title : actTitle;
        let upDes= actDes === "" ? resource.description : actDes; 
        let upCategory = category === "" ? resource.category : category;
        let upPage = page === "" ? resource.page !== undefined ? resource.page : "" : page;
        let upDate = date === "" ? resource.date : date; 
        console.log("updateLinkArr>>",updateLinkArr)
        updateResourcesData(resource.id , upRitle, upDes, upCategory,upPage, upDate, updateLinkArr.length === 0 ? linkArr : updateLinkArr);
        handleClose();
    }


    return (
        <>
            <Modal show={props.show} centered  size="lg">
            <Modal.Header closeButton onClick={props.close}>
            <Modal.Title>Add New Resource</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-4 row">
                        <Form.Label className='col-md-3'>Resource Title: <strong>*</strong></Form.Label>
                        {props.status === "edit" ? (
                            <Form.Control className='col-md-8' type="text" placeholder="Enter Resource Title" defaultValue={resource.title} onChange={e => setTitle(e.target.value)}/>
                        ) : (
                            <Form.Control className='col-md-8' type="text" placeholder="Enter Resource Title" onChange={e => setTitle(e.target.value)}/>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-4 row">
                        <Form.Label className='col-md-3'>Resource Description :  <strong>*</strong></Form.Label>
                        {props.status === "edit" ? (
                            <Form.Control className='col-md-8' as="textarea" rows={3} placeholder="Enter Resource Description" defaultValue={resource.description} onChange={e => setDes(e.target.value)}/>
                        ) : (
                            <Form.Control className='col-md-8' as="textarea" rows={3} placeholder="Enter Resource Description" onChange={e => setDes(e.target.value)}/>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-4 row">
                        <Form.Label className='col-md-3'>Upload Photo:<strong>*</strong></Form.Label>

                        {props.status === "edit" ? (
                            <Col md={8} className="p-0">
                                <img src={resource.image} className="border w-75" alt={resource.image} />
                                {/* <Form.Group controlId="formFileSm" className="col-md-9 fileInput">
                                    <Form.Control type="file" onChange={(e) => {setCoverUpload(e.target.files[0]);}}/>
                                </Form.Group> */}
                            </Col>
                        ) : (
                            <Form.Group controlId="formFileSm" className="col-md-9 fileInput">
                                <Form.Control type="file" onChange={(e) => {setCoverUpload(e.target.files[0]);}}/>
                            </Form.Group>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-4 row">
                        <Form.Label className='col-md-3'> Date :  <strong>*</strong></Form.Label>
                        {props.status === "edit" ? (
                            <Col md={8} className="p-0">
                                <Form.Control  defaultValue={moment(resource.date, "DD-MM-YYYY").locale('en').format('YYYY-MM-DD')} type="date" onChange={e => setDate(new Date(e.target.value).toLocaleDateString("en-GB"))} />
                            </Col>
                        ) : (
                            <Col md={8} className="p-0">
                                <Form.Control className='col-md-9' type="date" onChange={e => setDate(new Date(e.target.value).toLocaleDateString("en-GB"))}/>
                            </Col>
                        )}
                    </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-3'> Category :  <strong>*</strong></Form.Label> 
                                {props.status === "edit" ? (
                                    <Col md={8} className="p-0">  
                                       <Form.Select defaultValue={resource.category} onChange={e => setCategory(e.target.value)} placeholder='Add Category for your Resource'>
                                            {/* <option>Add Category for your Resource</option> */}
                                            <option value="education">Education</option>
                                            <option value="wasteeducation">Waste Education</option>
                                            <option value="activity">Waste Reduction Activity</option>
                                            <option value="community">Community Waste Management</option>
                                            <option value="research">Research</option>
                                            <option value="other">Other</option>
                                        </Form.Select>
                                    </Col>
                                ) : (
                                    <Col md={7} className='p-0'> 
                                        <Form.Select aria-label="Default select example" onChange={e => setCategory(e.target.value)}>
                                            {/* <option>Add Category for your Resource</option> */}
                                            <option value="education">Education</option>
                                            <option value="wasteeducation">Waste Education</option>
                                            <option value="activity">Waste Reduction Activity</option>
                                            <option value="community">Community Waste Management</option>
                                            <option value="research">Research</option>
                                            <option value="other">Other</option>
                                        </Form.Select>
                                    </Col>
                                )} 
                        </Form.Group>

                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-3'>{props.status === "edit" ? "Update Page :" : "Add  Page :"} <strong>*</strong></Form.Label>
                            <Col md={8} className='p-0'>
                                {props.status === "edit" ? ( 
                                    <Form.Select defaultValue={resource.page !== undefined ? resource.page : ""}  onChange={e => setPage(e.target.value)}>
                                        {/* <option>Select Your Page</option> */}
                                        <option value="university">University</option>
                                        <option value="school">School</option>
                                    </Form.Select>
                                ) : ( 
                                    <Form.Select aria-label="Default select example"  onChange={e => setPage(e.target.value)}>
                                        {/* <option>Select Your Page</option> */}
                                        <option value="university">University</option>
                                        <option value="school">School</option>
                                    </Form.Select>
                                )}
                            </Col> 
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-3'>Resource Links: <strong>*</strong></Form.Label>
                            <Col md={8} className='row mt-0'>
                                {props.status === "edit" ? (
                                    <>
                                        {resource.links.length > 0 ? (
                                            <>
                                                {resource.links.map((data, index) =>
                                                    <Row key={index} className='p-0'>
                                                        <strong> {data.title}</strong> 
                                                        <div className='d-flex flex-row'>
                                                            <label><BsLink45Deg  className='me-3 text-success fs-5'/> </label>
                                                            <p className='d-inline'>{data.link} </p>
                                                            <label><BsPencilSquare className='ms-3 text-warning fs-5' onClick={() => {handleShow({ data });setLinkData(data)}} /></label>
                                                        </div>             
                                                        <Modal show={show} centered className='bg-dark'>
                                                            <Modal.Header>
                                                                <Modal.Title> Update Resource Link</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <Form.Group className="mb-4 row">
                                                                    <Form.Label className='col-md-4'> Resource Title :  <strong>*</strong></Form.Label>
                                                                    <Form.Control className='col-md-8 me-2' type="text" value={resTitle !== null ? resTitle : linkData.title} placeholder="Enter Resource Title Here" onChange={e => setresTitle(e.target.value)} /> 
                                                                </Form.Group>
                                                                <Form.Group className="mb-4 row">
                                                                    <Form.Label className='col-md-4'> Resource Link :  <strong>*</strong></Form.Label>
                                                                    <Form.Control className='col-md-8 me-2' type="text" value={reslink !== null ? reslink : linkData.link} placeholder="Enter Resource Link Here" onChange={e => setLink(e.target.value)} />
                                                                </Form.Group>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={handleLinkClose}>
                                                                    Cancel
                                                                </Button>
                                                                <Button variant="warning" onClick={() => updateLinks(index)}>
                                                                    Update
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </Row>
                                                )}
                                            </>
                                        ): (
                                            <>
                                                {linkArr.length > 0 && (
                                                    <>
                                                        {linkArr.map((data, index) =>
                                                            <Row key={index} className='p-0'>
                                                                  <strong> {data.title}</strong> 
                                                                  <Col md={12} className='row'>
                                                                    <BsLink45Deg className='col-md-1 link-icon text-success'/> <label  className='col-md-10'>{data.link}</label>
                                                                </Col> 
                                                            </Row>
                                                        )}
                                                    </>
                                                )} 
                                                <Button variant="secondary" className='col-md-3' onClick={handleShow}><BsPlusSquareDotted className='me-2'/> Add Link</Button> 
                                                <Modal show={show} centered className='bg-dark'>
                                                    <Modal.Header>
                                                        <Modal.Title>Add Resource Link</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form.Group className="mb-4 row">
                                                            <Form.Label className='col-md-4'> Resource Title :  <strong>*</strong></Form.Label>
                                                            <Form.Control className='col-md-8 me-2' type="text" placeholder="Enter Resource Title Here" onChange={e => setresTitle(e.target.value)} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-4 row">
                                                            <Form.Label className='col-md-4'> Resource Link :  <strong>*</strong></Form.Label>
                                                            <Form.Control className='col-md-8 me-2' type="text" placeholder="Enter Resource Link Here" onChange={e => setLink(e.target.value)} />
                                                        </Form.Group>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleLinkClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="warning" onClick={addLinks}>
                                                            Add
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </>
                                        )}  
                                    </>
                                ) : (
                                    <>
                                        {linkArr.length > 0 && (
                                            <>
                                                {linkArr.map((data, index) =>
                                                    <Row key={index} className='p-0'>
                                                        <strong> {data.title}</strong>
                                                         <Col md={12} className='row'>
                                                            <BsLink45Deg className='col-md-1 link-icon text-success'/> <label  className='col-md-10'>{data.link}</label>
                                                        </Col> 
                                                    </Row>
                                                )}
                                            </>
                                        )} 
                                        <Button variant="secondary" className='col-md-3' onClick={handleShow}><BsPlusSquareDotted className='me-2'/> Add Link</Button> 
                                        <Modal show={show} centered className='bg-dark'>
                                                    <Modal.Header>
                                                        <Modal.Title>Add Resource Link</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form.Group className="mb-4 row">
                                                            <Form.Label className='col-md-4'> Resource Title :  <strong>*</strong></Form.Label>
                                                            <Form.Control className='col-md-8 me-2' type="text" placeholder="Enter Resource Title Here" onChange={e => setresTitle(e.target.value)} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-4 row">
                                                            <Form.Label className='col-md-4'> Resource Link :  <strong>*</strong></Form.Label>
                                                            <Form.Control className='col-md-8 me-2' type="text" placeholder="Enter Resource Link Here" onChange={e => setLink(e.target.value)} />
                                                        </Form.Group>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleLinkClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="warning" onClick={addLinks}>
                                                            Add
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                    </>
                                )}
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {props.status === "edit" ? (
                        <Button variant="primary" onClick={updateResources}> Update Resource </Button>
                    ) : (
                        <Button variant="primary" onClick={createResources}> Create Resource </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateActivity;