import React, { useState } from 'react'; 
import { Modal, Button, Form, Col } from "react-bootstrap";
import { storage, createNews, updateNews } from '../../firebase.js';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./NewsModal.css"
import moment from 'moment'

const NewsModal = (props) => {
    const newsInfo = props.data;
    const [newstitle, setNtitle] = useState("");
    const [newsdes, setNewDes] = useState("");
    const [newslink, setNlink] = useState("");
    const [newscoverimgUrl, setNimage] = useState(null);
    const [newsdate, setNdate] = useState("");
    // const [newsdate, setNdate] = useState(new Date().toLocaleDateString("en-GB"));
    const [newscategory, setNcategory] = useState(null);
    const handleClose = props.close;
    const [show, setShow] = useState(false);

    const setCoverUpload = (imageUpload) => {
        if (imageUpload === null) return;
        const imageRef = ref(storage, `news/${imageUpload.name}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setNimage(url)
            });
        });
    }

    const createNew = () => {
        createNews(newstitle, newsdes, newscoverimgUrl, newsdate, newscategory, newslink).then((res) => {
            console.log(res)
            handleClose();
            window.location.reload();
        });
    }

    const updateNew = () => {
        let updateTitle = newstitle === "" ? newsInfo.ntitle : newstitle;
        let updateDes = newsdes === "" ? newsInfo.ndescription : newsdes;
        let updatecategory = newscategory === "" ? newsInfo.ncategory : newscategory;
        let updatedate = newsdate === "" ? newsInfo.ndate : newsdate; 
        let updatelink = newslink === "" ? newsInfo.nlink : newslink; 
        updateNews(newsInfo.nid ,updateTitle, updateDes, updatedate, updatecategory, updatelink);
        handleClose(); 
    }

    return ( 
        <>
            <Modal show={props.show} centered size="lg">
                <Modal.Header closeButton onClick={props.close}>
                    <Modal.Title>Add News</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>Title : <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0"> 
                                {props.status === "edit" ? (
                                    <Form.Control type="text" placeholder="Enter Book Title" defaultValue={newsInfo.ntitle} onChange={e => setNtitle(e.target.value)} />
                                ) : (
                                    <Form.Control type="text" placeholder="Enter Title" onChange={e => setNtitle(e.target.value)} />
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Description :  <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0"> 
                                {props.status === "edit" ? (
                                    <Form.Control as="textarea" rows={5} placeholder="Enter Description" defaultValue={newsInfo.ndescription} onChange={e => setNewDes(e.target.value)}/>
                                ) : (
                                    <Form.Control as="textarea" rows={5} placeholder="Enter Description" onChange={e => setNewDes(e.target.value)}/>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>Upload Cover Image:<strong>*</strong></Form.Label>
                            <Col md={8} className="p-0">
                                {props.status === "edit" ? (
                                        <img src={newsInfo.nimage} className="border w-75" alt={newsInfo.nimage} />
                                ) : (
                                    <Form.Group controlId="formFileSm" className="fileInput">
                                        <Form.Control type="file" onChange={(e) => { setCoverUpload(e.target.files[0]); }} />
                                    </Form.Group>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Category :  <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0"> 
                                {props.status === "edit" ? (
                                    <Form.Select defaultValue={newsInfo.ncategory}  onChange={e => setNcategory(e.target.value)}>
                                        <option>Add Category for your News & Update</option>
                                        <option value="news">News</option>
                                        <option value="update">Update</option>
                                    </Form.Select> 
                                ) : ( 
                                    <Form.Select aria-label="Default select example" onChange={e => setNcategory(e.target.value)}>
                                        <option>Add Category for your News & Update</option>
                                        <option value="news">News</option>
                                        <option value="update">Update</option>
                                    </Form.Select>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Date :  <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0"> 
                                {props.status === "edit" ? (
                                    <Form.Control  defaultValue={moment(newsInfo.ndate, "DD-MM-YYYY").locale('en').format('YYYY-MM-DD')} type="date" onChange={e => setNdate(new Date(e.target.value).toLocaleDateString("en-GB"))} />
                                ) : (
                                    <Form.Control type="date" onChange={e => setNdate(new Date(e.target.value).toLocaleDateString("en-GB"))} />
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>Link : <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0"> 
                                {props.status === "edit" ? (
                                    <Form.Control type="text" placeholder="Enter Link" defaultValue={newsInfo.nlink} onChange={e => setNlink(e.target.value)} />
                                ) : (
                                    <Form.Control type="text" placeholder="Enter Link" onChange={e => setNlink(e.target.value)} />
                                )}
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {props.status === "edit" ? ( 
                        <Button variant="primary" onClick={updateNew}> Update News </Button>
                    ) : (
                        <Button variant="primary" onClick={createNew}> Create News </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NewsModal;