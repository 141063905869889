import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col } from "react-bootstrap";
import { storage, createCourse, updateCourse } from '../../firebase.js';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "../ResourceModal/ResourceModal.css"
import moment from 'moment'

const CreateActivity = (props) => {
    const course = props.course; 
    console.log("course>>>>>",course)
    const [courseTitle, setTitle] = useState("");
    const [courseDes, setDes] = useState("");
    const [courseImage, setImageUrl] = useState(null);
    const handleClose = props.close;
    const [date, setDate] = useState("");
    const [courseCategory, setCategory] = useState("");
    const [page, setPage] = useState("");
    const [udemyUrl, setUdemyUrl] = useState(course.udemylink !== null ? course.udemylink : null);
    const [youTubeUrl, setYouTubeUrl] = useState(course.youtubelink !== null ? course.youtubelink : null);
    const [otherUrl, setOtherUrl] = useState(course.otherlink !== null ? course.otherlink : null); 
    const [labelArr, setLabelLists] = useState(course.labels === undefined ? [] : course.labels);
    const [isChecked, setIsChecked] = useState(false);
    
    useEffect(() => {
        setLabelLists(course.labels);
    }, [course.labels])


    const setCoverUpload = (imageUpload) => {
        console.log(imageUpload)
        if (imageUpload === null) return;
        const imageRef = ref(storage, `courses/${imageUpload.name}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setImageUrl(url)
            });
        });
    }

    const createCourses = () => {
        if(courseCategory === ""){
            setCategory("Waste Management")
        }
        if(page === ""){
            setPage("education")
        }
        createCourse(courseTitle, courseDes, courseImage, date, courseCategory, labelArr, page, udemyUrl, youTubeUrl, otherUrl).then((res) => {
            handleClose();
            window.location.reload();
        });
    }

    const updateCourseInfo = () => {
        let upRitle = courseTitle === "" ? course.title : courseTitle;
        let upDes = courseDes === "" ? course.description : courseDes;
        let upCategory = courseCategory === "" ? course.category : courseCategory;
        // let upLabels = labelArr.length === 0 ? [] : labelArr; 
        let upLabels = labelArr; 
        let upDate = date === "" ? course.date : date;
        let upPage = page === "" ? course.page : page;
        let upUdemyLink = udemyUrl === "" || udemyUrl === undefined ? course.udemylink : udemyUrl;
        let upYoutubeLink = youTubeUrl === "" || youTubeUrl === undefined ? course.youtubelink : youTubeUrl;
        let upOtherLink = otherUrl === "" || otherUrl === undefined ? course.otherlink === undefined ? "" : course.otherlink : otherUrl;
        updateCourse(course.id, upRitle, upDes, upDate, upCategory,upLabels, upPage, upUdemyLink , upYoutubeLink, upOtherLink);
    }
    const handleOnChange = () => {
        setIsChecked(!isChecked);
        setLabelLists(Object.entries(course.labels).map(([key, value]) => value));
    };
    
    const  handleLabelChange = (event) => { 
        const target = event.target
        const checked = target.checked
        const name = target.name
        const getFundOrgInfo = {name,checked};
        console.log("getFundOrgInfo>>>",getFundOrgInfo)
        
        if (labelArr.filter((item) => item.name === name) && checked === true) {
            setLabelLists(prevArray => [...prevArray, getFundOrgInfo]);
        }else if(labelArr.filter((item) => item.name === name) && checked === false){
            setLabelLists(labelArr.filter((item) => item.name !== name));
        }else{
            setLabelLists(prevArray => [...prevArray, getFundOrgInfo])
        }

    }

    return (
        <>
            <Modal show={props.show} centered size="lg">
                <Modal.Header closeButton onClick={props.close}>
                    <Modal.Title>{props.status === "edit" ? "Edit Course" : "Add New Course"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>{props.status === "edit" ? "Update Title:" : "Course Title:"} <strong>*</strong></Form.Label>
                            {props.status === "edit" ? (
                                <Form.Control className='col-md-8' type="text" placeholder="Enter Course Title" defaultValue={course.title} onChange={e => setTitle(e.target.value)} />
                            ) : (
                                <Form.Control className='col-md-8' type="text" placeholder="Enter Course Title" onChange={e => setTitle(e.target.value)} />
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>{props.status === "edit" ? "Update Description :" : "Course Description :"}<strong>*</strong></Form.Label>
                            {props.status === "edit" ? (
                                <Form.Control className='col-md-8' as="textarea" rows={3} placeholder="Enter Course Description" defaultValue={course.description} onChange={e => setDes(e.target.value)} />
                            ) : (
                                <Form.Control className='col-md-8' as="textarea" rows={3} placeholder="Enter Course Description" onChange={e => setDes(e.target.value)} />
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>{props.status === "edit" ? "Photo :" : "Upload Photo :"}<strong>*</strong></Form.Label>

                            {props.status === "edit" ? (
                                <Col md={8} className="p-0">
                                    <img src={course.image} className="border w-75" alt={course.image} />
                                </Col>
                            ) : (
                                <Form.Group controlId="formFileSm" className="col-md-8 fileInput">
                                    <Form.Control type="file" onChange={(e) => { setCoverUpload(e.target.files[0]); }} />
                                </Form.Group>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>{props.status === "edit" ? "Update Date :" : "Add Date :"} <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0"> 
                                {props.status === "edit" ? (
                                    <Form.Control  defaultValue={moment(course.date, "DD-MM-YYYY").locale('en').format('YYYY-MM-DD')} type="date" onChange={e => setDate(new Date(e.target.value).toLocaleDateString("en-GB"))} />
                                
                                ) : (
                                    <Form.Control type="date" onChange={e => setDate(new Date(e.target.value).toLocaleDateString("en-GB"))} />
                                )}
                             </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> {props.status === "edit" ? "Update Category :" : "Add Category :"} <strong>*</strong></Form.Label>
                            <Col md={7} className='p-0'>
                                {props.status === "edit" ? (
                                    <Form.Select placeholder='Add Category for your Resource' defaultValue={course.category} onChange={e => setCategory(e.target.value)}>
                                        {/* <option>Add Category for your Resource</option> */}
                                        <option value="waste">Waste Management</option>
                                        <option value="development">Conservation & Sustainable Development</option>
                                        <option value="marine">Marine Science</option>
                                        <option value="geospatial">Geospatial Technology</option>
                                        <option value="geology">Geology</option>
                                    </Form.Select> 
                                ) : ( 
                                    <Form.Select placeholder='Add Category for your Resource' aria-label="Default select example"  onChange={e => setCategory(e.target.value)}>
                                        {/* <option>Add Category for your Resource</option> */}
                                        <option value="waste">Waste Management</option>
                                        <option value="development">Conservation & Sustainable Development</option>
                                        <option value="marine">Marine Science</option>
                                        <option value="geospatial">Geospatial Technology</option>
                                        <option value="geology">Geology</option>
                                    </Form.Select>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Add Label :  <strong>*</strong></Form.Label> 
                            {props.status === "edit" ? (
                                <Col md={7} className='p-0 row'> 
                                    {['waste',"development","marine", "geospatial", "geology"].map((label) => ( 
                                        <>
                                        {Object.values(labelArr === undefined ? [] : labelArr).map((item) => item.name).includes(label) === true ? ( 
                                                <div key={'org_'+label} className="mb-3 col-md-4"> 
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={label}
                                                        label={label}
                                                        name={label}
                                                        checked={true}
                                                        onChange={e => handleLabelChange(e)}
                                                    />
                                                </div> 
                                                
                                            ) : (
                                                <div key={'org_'+label} className="mb-3 col-md-4"> 
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={label}
                                                        label={label}
                                                        name={label} 
                                                        onChange={e => handleLabelChange(e)}
                                                    />
                                                </div> 
                                            )} 
                                        </>
                                    ))}
                                </Col>
                            ) : (
                                <Col md={7} className='p-0 row'> 
                                    {['waste',"development","marine", "geospatial", "geology"].map((label) => (
                                        <div key={label} className="mb-3 col-md-4">
                                            <Form.Check
                                                type="checkbox"
                                                id={label}
                                                label={label}
                                                name={label}
                                                onChange={e => handleLabelChange(e)}
                                            />
                                        </div>
                                    ))}
                                </Col>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>{props.status === "edit" ? "Update Page :" : "Add  Page :"} <strong>*</strong></Form.Label>
                            <Col md={7} className='p-0'>
                                {props.status === "edit" ? ( 
                                    <Form.Select placeholder='Select Your Page' defaultValue={course.page}  onChange={e => setPage(e.target.value)}>
                                        {/* <option>Select Your Page</option> */}
                                        <option value="education">Education</option>
                                        <option value="rural">Rural</option>
                                        <option value="urban">Urban</option>
                                    </Form.Select>
                                ) : ( 
                                    <Form.Select  placeholder='Select Your Page' aria-label="Default select example"  onChange={e => setPage(e.target.value)}>
                                        {/* <option>Select Your Page</option> */}
                                        <option value="education">Education</option>
                                        <option value="rural">Rural</option>
                                        <option value="urban">Urban</option>
                                    </Form.Select>
                                )}
                            </Col> 
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> Udemy :  <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0">
                                {props.status === "edit" ? (
                                    <>
                                        {course.udemylink !== null ? (
                                            <Form.Control type="text" defaultValue={course.udemylink} onChange={e => setUdemyUrl(e.target.value)} /> 
                                        ) : (
                                            <Form.Control type="text" placeholder='Enter Udemy Link' onChange={e => setUdemyUrl(e.target.value)} />
                                        )}
                                    </>
                                ) : (
                                    <Form.Control type="text" placeholder='Enter Udemy Link' onChange={e => setUdemyUrl(e.target.value)} />
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'> YouTube :  <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0">
                                {props.status === "edit" ? (
                                    <>
                                        { course.youtubelink !== null ? (
                                            <Form.Control type="text" defaultValue={course.youtubelink} onChange={e => setYouTubeUrl(e.target.value)} />
                                        ) : (
                                            <Form.Control type="text" placeholder='Enter Youtube Link' onChange={e => setYouTubeUrl(e.target.value)} />
                                        )}
                                   </>
                                ) : (
                                    <Form.Control type="text" placeholder='Enter Youtube Link' onChange={e => setYouTubeUrl(e.target.value)} />
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Form.Label className='col-md-4'>Asia Water Academy :  <strong>*</strong></Form.Label>
                            <Col md={7} className="p-0">
                                {props.status === "edit" ? (
                                    <>
                                        { course.otherlink !== null ? (
                                            <Form.Control type="text" defaultValue={course.otherlink} onChange={e => setOtherUrl(e.target.value)} />
                                        ) : (
                                            <Form.Control type="text" placeholder='Enter Additional Link' onChange={e => setOtherUrl(e.target.value)} />
                                        )}
                                   </>
                                ) : (
                                    <Form.Control type="text" placeholder='Enter Additional Link' onChange={e => setOtherUrl(e.target.value)} />
                                )}
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {props.status === "edit" ? (
                        <Button variant="primary" onClick={updateCourseInfo}> Update Course </Button>
                    ) : (
                        <Button variant="primary" onClick={createCourses}> Create Course </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateActivity;