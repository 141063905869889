import React, { useEffect, useState } from 'react';
import { getActivitiesData, removeResourceData } from '../../firebase.js';
import { Navbar, Container, Row, Col, Table, Button, Form, Modal } from "react-bootstrap";
import "../Dashboard/Dashboard.css"; 
import { BiEdit , BiTrash} from "react-icons/bi";
import { BsLink45Deg } from "react-icons/bs";
import ResourceModal from "../ResourceModal/ResourceModal"; 
import Loading from "../Loading/Loading"
import Sidebar from "../Sidebar/Sidebar";

function Resources() {
    const [resourceLists, addData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState("create");
    const [resourceData, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [showDelete, setShow] = useState(false);
    const [delresourceData, setDeleteData] = useState({});

    useEffect(() => {
        getActivitiesData().then((lists) => {
            var arrActivity = [];
            lists.forEach((ele) => {
                arrActivity.push(ele.data());
            });
            addData(arrActivity);
            setLoading(false)
        }).catch((err) => console.log(err));
    }, []);
    console.log(resourceLists)

    function editActivityInfo(activity) {
        let getActInfo = activity.data;
        setShowModal(true)
        setStatus("edit")
        setData(actInfo => ({ ...actInfo, ...getActInfo }));
    }  
    
    function deleteResource() {
        let resourceData = delresourceData.data;
        setShow(false)
        addData(resourceLists.filter((item) => item.id !== resourceData.id));
        setLoading(true);
        removeResourceData(resourceData.id).then(() => {
            setTimeout(() => setLoading(false), 500)
        });
    }

    const searchItems = (searchValue) => { 
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = resourceLists.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            console.log(filteredData) 
            setFilteredResults(filteredData)
        }else{
            setFilteredResults(resourceLists)
        }
    }

    return ( 
        <>
        {loading === false ? (
            <Container fluid>
                <Row> 
                    <Sidebar/> 
                    <Col lg={10} md={9} className="ms-sm-auto px-md-4">
                        <Navbar>
                            <h3 className='py-3'>All Resources</h3><h3 className='py-3 slagcolor'></h3> 
                            <Col md={7} className='py-3'>
                                <Form className='p-1 col-md-8 mx-auto searchBox'>
                                    <Form.Control type="text" placeholder="Search by Resources" className='text-center rounded-lg'  onChange={(e) => searchItems(e.target.value)}/>
                                </Form>
                            </Col>
                            <Col md={3} className="d-flex align-items-center justify-content-end">
                                <Button variant="primary"  onClick={() => {setShowModal(true); setStatus("create")}}>Create Resource</Button>
                                <ResourceModal show={showModal} close={() => setShowModal(false)} status={status} resource={resourceData} />
                            </Col>
                        </Navbar> 
                        <Container className='datacontainer p-0'>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Title</th>
                                        <th>Image</th>
                                        <th>Description</th>
                                        <th>Category</th>
                                        <th>Page</th>
                                        <th>Date</th>
                                        <th>Links</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className='tabledata'>
                                    {searchInput.length > 1 ? (
                                        <> 
                                            {filteredResults.map((data, index) =>(
                                                <tr key={index} className="py-4">
                                                    <td><label>{index+1}.</label></td>
                                                    <td className='col-md-2'><label>{data.title}</label></td>
                                                    <td className='col-md-3'><img src={data.image} className="img-fluid" alt={data.image}/></td>
                                                    <td className='col-md-5'><label>{data.description}</label></td>
                                                    <td className='col-md-1'><label>{data.category}</label></td>
                                                    <td className='col-md-1'><label>{data.page}</label></td>
                                                    <td className='col-md-1'><label>{data.date}</label></td>
                                                    <td className='col-md-1'>{data.links.map((url, index) =>
                                                        <a href={url.link} target='_blank' key={index} className='d-block text-success'> Resource {index+1} </a>)}
                                                    </td>
                                                    <td className='editIcon'> <BiEdit onClick={() => editActivityInfo({data})}/><BiTrash onClick={() => {setShow(true);setDeleteData({ data })}} /></td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <>  
                                            {resourceLists.map((data, index) =>(
                                                <tr key={index} className="py-4">
                                                    <td><label>{index+1}.</label></td>
                                                    <td className='col-md-2'><label>{data.title}</label></td>
                                                    <td className='col-md-2'><img src={data.image} className="img-fluid" alt={data.image}/></td>
                                                    <td className='col-md-3'><label>{data.description}</label></td>
                                                    <td className='col-md-1'><label>{data.category}</label></td>
                                                    <td className='col-md-1'><label>{data.page}</label></td>
                                                    <td className='col-md-1'><label>{data.date}</label></td>
                                                    <td className='col-md-1'>{data.links.map((url, index) =>
                                                        <a href={url.link} target='_blank' key={index} className='d-block text-success'> Resource {index+1} </a>)}
                                                    </td>
                                                    <td className='editIcon'> <BiEdit onClick={() => editActivityInfo({data})}/><BiTrash onClick={() => {setShow(true);setDeleteData({ data })}} /></td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Container>
                    </Col>
                </Row>
                <Modal show={showDelete} onHide={() => setShow(false)} centered aria-labelledby="deleteModal">
                    <Modal.Header closeButton>
                    <Modal.Title id="deleteModal">
                        Are you sure to delete this resource?
                    </Modal.Title>
                    </Modal.Header> 
                    <Modal.Footer>
                            <Button variant="danger"   onClick={() => setShow(false)}> No </Button>
                            <Button variant="success"  onClick={() => deleteResource()}> Yes</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        ) : (
            <Loading />
        )}
        </> 
    );
}
export default Resources;